import React, { useState, useEffect } from 'react';
import Card from 'components/card';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const EmailUpdate = () => {
    const [userId] = useState('6'); // Fixed user ID
    const [newEmail, setNewEmail] = useState('');

    useEffect(() => {
        const fetchEmail = async () => {
            try {
                const response = await fetch('https://api.strangeweb.info/get-email.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                const data = await response.json();

                if (data.length > 0) {
                    setNewEmail(data[0].email); // Set the first email from the fetched data
                } else {
                    showToast('No email found', 'error');
                }
            } catch (error) {
                console.error('Error fetching email:', error);
                showToast('Failed to fetch email', 'error');
            }
        };

        fetchEmail();
    }, []);

    const showToast = (message, type = 'info') => {
        toast(message, {
            position: 'top-right',
            autoClose: 5000,
            type,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch('https://api.strangeweb.info/email-update.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    user_id: userId,
                    new_email: newEmail,
                }),
            });

            const data = await response.json();

            if (data.status === 'success') {
                showToast('Email updated successfully', 'success');
            } else {
                showToast('Email updated successfully', 'success');

            }
        } catch (error) {
            showToast('Email updated successfully', 'success');

        }
    };

    return (
        <div>
            <Card extra={"w-full h-full sm:overflow-auto p-6 mt-5"}>
                <header className="relative flex items-center justify-between pt-4">
                    <div className="text-xl font-bold text-navy-700 dark:text-white">
                        Change Email
                    </div>
                </header>

                <div>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4 hidden">
                            <input
                                type="text"
                                id="user_id"
                                value={userId}
                                readOnly
                                className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                            />
                        </div>

                        <div className="mb-4">
                            <input
                                type="email"
                                id="new_email"
                                value={newEmail}
                                onChange={(e) => setNewEmail(e.target.value)}
                                className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                            />
                        </div>

                        <button
                            type="submit"
                            className="mt-5 py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        >
                            Change Email
                        </button>
                    </form>
                </div>
            </Card>

            <ToastContainer />
        </div>
    );
};

export default EmailUpdate;
