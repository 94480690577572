import React, { useState, useEffect } from 'react';
import Card from 'components/card';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const Enquires = () => {
    const [enquires, setEnquires] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    // Search state
    const [searchTerm, setSearchTerm] = useState('');

    // Selection state
    const [selectedIds, setSelectedIds] = useState([]);

    useEffect(() => {
        fetch('https://api.strangeweb.info/all-enquires.php')
            .then(response => response.json())
            .then(data => {
                setEnquires(data);
                setLoading(false);
            })
            .catch(err => {
                setError(err);
                setLoading(false);
                toast.error('Failed to fetch enquiries.');
            });
    }, []);

    const handleDelete = (id) => {
        fetch(`https://api.strangeweb.info/all-enquires.php?id=${id}`, {
            method: 'DELETE',
        })
        .then(response => response.json())
        .then(data => {
            if (data.message === 'Record deleted successfully') {
                setEnquires(enquires.filter(enquiry => enquiry.id !== id));
                toast.success('Enquiry deleted successfully.');
            } else {
                toast.error('Failed to delete enquiry.');
            }
        })
        .catch(err => {
            toast.error('Error occurred while deleting enquiry.');
        });
    };

    const handleDeleteSelected = () => {
        Promise.all(selectedIds.map(id =>
            fetch(`https://api.strangeweb.info/all-enquires.php?id=${id}`, {
                method: 'DELETE',
            }).then(response => response.json())
        ))
        .then(results => {
            const failedDeletes = results.filter(result => result.message !== 'Record deleted successfully');
            if (failedDeletes.length > 0) {
                toast.error('Some deletions failed.');
            } else {
                setEnquires(enquires.filter(enquiry => !selectedIds.includes(enquiry.id)));
                toast.success('Selected enquiries deleted successfully.');
            }
            setSelectedIds([]);
        })
        .catch(err => {
            toast.error('Error occurred while deleting selected enquiries.');
        });
    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setSelectedIds(filteredEnquires.map(enquiry => enquiry.id));
        } else {
            setSelectedIds([]);
        }
    };

    const handleSelect = (id) => {
        setSelectedIds(prevSelectedIds => 
            prevSelectedIds.includes(id)
                ? prevSelectedIds.filter(selectedId => selectedId !== id)
                : [...prevSelectedIds, id]
        );
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    // Filter enquiries based on search term
    const filteredEnquires = enquires.filter(enquiry =>
        Object.values(enquiry).some(value =>
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    // Pagination calculations
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredEnquires.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(filteredEnquires.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1); // Reset to first page on search
    };

    return (
        <div>
            <Card extra={"w-full h-full sm:overflow-auto p-6 mt-5"}>
                <header className="relative flex items-center justify-between pt-4">
                    <div className="text-xl font-bold text-navy-700 dark:text-white">
                        All Enquiries
                    </div>
                </header>

                <div className="mb-4 mt-5">
                    <input
                        type="text"
                        placeholder="Search enquiries..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="w-full px-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>

                <div className="mb-4">
                    <button
                        className="px-4 py-2 bg-red-500 text-white rounded"
                        onClick={handleDeleteSelected}
                        disabled={selectedIds.length === 0}
                    >
                        Delete Selected
                    </button>
                </div>

                <div className="relative overflow-x-auto">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    <input
                                        type="checkbox"
                                        onChange={handleSelectAll}
                                        checked={selectedIds.length === filteredEnquires.length}
                                    />
                                </th>
                                <th scope="col" className="px-6 py-3">ID</th>
                                <th scope="col" className="px-6 py-3">Name</th>
                                <th scope="col" className="px-6 py-3">Email</th>
                                <th scope="col" className="px-6 py-3">Service</th>
                                <th scope="col" className="px-6 py-3">Phone</th>
                                <th scope="col" className="px-6 py-3">Message</th>
                                <th scope="col" className="px-6 py-3">Created At</th>
                                <th scope="col" className="px-6 py-3">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.length === 0 ? (
                                <tr>
                                    <td colSpan="9" className="px-6 py-3 text-center">No enquiries found</td>
                                </tr>
                            ) : (
                                currentItems.map(enquiry => (
                                    <tr key={enquiry.id}>
                                        <td className="px-6 py-3">
                                            <input
                                                type="checkbox"
                                                checked={selectedIds.includes(enquiry.id)}
                                                onChange={() => handleSelect(enquiry.id)}
                                            />
                                        </td>
                                        <td className="px-6 py-3">{enquiry.id}</td>
                                        <td className="px-6 py-3">{enquiry.name}</td>
                                        <td className="px-6 py-3">{enquiry.email}</td>
                                        <td className="px-6 py-3">{enquiry.service}</td>
                                        <td className="px-6 py-3">{enquiry.phone}</td>
                                        <td className="px-6 py-3">{enquiry.message}</td>
                                        <td className="px-6 py-3">{enquiry.created_at}</td>
                                        <td className="px-6 py-3">
                                            <button
                                                className="px-4 py-2 bg-red-500 text-white rounded"
                                                onClick={() => handleDelete(enquiry.id)}
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>

                {/* Pagination controls */}
                <div className="flex justify-center mt-4">
                    <button
                        className="px-4 py-2 mx-1 bg-gray-300 rounded"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        Previous
                    </button>
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index + 1}
                            className={`px-4 py-2 mx-1 rounded ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
                            onClick={() => handlePageChange(index + 1)}
                        >   
                            {index + 1}
                        </button>
                    ))}
                    <button
                        className="px-4 py-2 mx-1 bg-gray-300 rounded"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        Next
                    </button>
                </div>
            </Card>

            <ToastContainer />
        </div>
    );
};

export default Enquires;
