import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/tables";
import Services from "components/admin/services";
import Products from "components/admin/products";
import Enquires from "components/admin/enquries";
import ForgotPaddwrod from "components/admin/forgot-password";
import EmailUpgrade from "components/admin/email-update";
import ForgotPasswrod from "components/admin/forgot-password";
import RTLDefault from "views/rtl/default";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdLock,
  MdLogout,
  MdSettings,
  MdGrid3X3,
  MdShop,
  MdShop2,
  MdRecycling,
  MdList,
  MdEmail,
  MdPassword,
  MdLibraryBooks,
} from "react-icons/md";

const routes = [
  {
    name: "Home",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Gallery",
    layout: "/admin",
    path: "gallery",
    icon: <MdLibraryBooks className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
  },
  {
    name: "Portfolio",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "portfolio",
    component: <DataTables />,
  },
  {
    name: "Services",
    layout: "/admin",
    icon: <MdSettings className="h-6 w-6" />,
    path: "services",
    component: <Services />,
  },
  {
    name: "Products",
    layout: "/admin",
    icon: <MdShop2 className="h-6 w-6" />,
    path: "products",
    component: <Products />,
  },
  {
    name: "Contact Enquiries",
    layout: "/admin",
    icon: <MdList className="h-6 w-6" />,
    path: "enquiries",
    component: <Enquires />,
  },
  {
    name: "Email",
    layout: "/admin",
    icon: <MdEmail className="h-6 w-6" />,
    path: "edit-email",
    component: <EmailUpgrade />,
  },
  {
    name: "Forgot Password",
    layout: "/admin",
    icon: <MdPassword className="h-6 w-6" />,
    path: "forgot-passwrod",
    component: <ForgotPasswrod />,
  },
 
];
export default routes;
