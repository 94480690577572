import React, { useState, useEffect } from 'react';
import Card from 'components/card';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const Products = () => {
    const [productName, setProductName] = useState('');
    const [features, setFeatures] = useState(['']);
    const [message, setMessage] = useState('');
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [editProductId, setEditProductId] = useState(null);

    const showToast = (message) => {
        toast(message, {
            position: 'top-right',
            autoClose: 5000,
        });
    };

    const handleClick = (message) => {
        showToast(message);
        fetchData();
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch('https://api.strangeweb.info/read_products.php');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setData(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const featureList = features.join(', ');

        try {
            const response = await fetch(isEditing 
                ? 'https://api.strangeweb.info/create_product.php'
                : 'https://api.strangeweb.info/create_product.php', {
                method: isEditing ? 'PUT' : 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: isEditing ? editProductId : undefined,
                    product_name: productName,
                    features: featureList
                }),
            });

            const data = await response.json();

            if (data.success) {
                setMessage(data.message);
                setProductName('');
                setFeatures(['']);
                setIsEditing(false);
                handleClick(isEditing ? 'Product Updated' : 'Product Added');
            } else {
                setMessage(data.message);
                handleClick(isEditing ? 'Product Update Failed' : 'Product Addition Failed');
            }
        } catch (error) {
            setMessage('Error submitting form: ' + error.message);
        }
    };

    const handleFeatureChange = (index, value) => {
        const updatedFeatures = [...features];
        updatedFeatures[index] = value;
        setFeatures(updatedFeatures);
    };

    const addFeatureField = () => {
        setFeatures([...features, '']);
        handleClick('Feature Field Added');
    };

    const removeFeatureField = (index) => {
        const updatedFeatures = features.filter((_, i) => i !== index);
        setFeatures(updatedFeatures);
        handleClick('Feature Field Removed');
    };

    const handleDelete = async (id) => {
        try {
            const response = await fetch('https://api.strangeweb.info/delete_product.php', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id }),
            });

            const data = await response.json();

            if (response.ok) {
                fetchData(); // Refresh data after deletion
                handleClick('Product Deleted');
            } else {
                handleClick('Product Deletion Failed');
            }
        } catch (error) {
            console.error('Error:', error);
            handleClick('Network error or server not reachable');
        }
    };

    const handleMultipleDelete = async () => {
        try {
            await Promise.all(
                selectedProducts.map(async (id) => {
                    const response = await fetch('https://api.strangeweb.info/delete_product.php', {
                        method: 'DELETE',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({ id }),
                    });
                    if (!response.ok) throw new Error('Deletion failed');
                })
            );
            fetchData(); // Refresh data after deletion
            handleClick('Products Deleted');
            setSelectedProducts([]);
        } catch (error) {
            console.error('Error:', error);
            handleClick('Error deleting products');
        }
    };

    const handleSelectProduct = (id) => {
        setSelectedProducts((prevSelected) =>
            prevSelected.includes(id) ? prevSelected.filter((item) => item !== id) : [...prevSelected, id]
        );
    };

    const handleEdit = (product) => {
        setProductName(product.product_name);
        setFeatures(product.features.split(', '));
        setIsEditing(true);
        setEditProductId(product.id);

        // Scroll to the top of the page
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Optional: adds smooth scrolling
        });
    };

    const filteredData = data.filter((item) =>
        item.product_name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div>
            <Card extra={"w-full h-full sm:overflow-auto p-6 mt-5"}>
                <header className="relative flex items-center justify-between pt-4">
                    <div className="text-xl font-bold text-navy-700 dark:text-white">
                        {isEditing ? 'Edit Product' : 'Add Product'}
                    </div>
                </header>

                <div>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label>Product Name:</label>
                            <input
                                type="text"
                                value={productName}
                                onChange={(e) => setProductName(e.target.value)}
                                className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                            />
                        </div>

                        <div>
                            {features.map((feature, index) => (
                                <div key={index} className="flex items-center">
                                    <div className="w-full">
                                        <label>{`Feature ${index + 1}:`}</label>
                                        <input
                                            type="text"
                                            value={feature}
                                            onChange={(e) => handleFeatureChange(index, e.target.value)}
                                            className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                                        />
                                    </div>
                                    {features.length > 1 && (
                                        <button
                                            type="button"
                                            onClick={() => removeFeatureField(index)}
                                             className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                                        >
                                            Remove
                                        </button>
                                    )}
                                </div>
                            ))}
                        </div>

                        <button
                            type="button"
                            onClick={addFeatureField}
                            className="mt-5 py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        >
                            Add Feature
                        </button>

                        <button
                            type="submit"
                            className="mt-5 py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        >
                            {isEditing ? 'Update' : 'Submit'}
                        </button>
                    </form>
                </div>
            </Card>

            <Card extra={"w-full h-full sm:overflow-auto p-6 mt-5"}>
                <header className="relative flex items-center justify-between pt-4">
                    <div className="text-xl font-bold text-navy-700 dark:text-white">
                        All Products
                    </div>
                </header>

                <div className="relative overflow-x-auto">
                    <div className="flex justify-between items-center mb-4">
                        <input
                            type="text"
                            placeholder="Search products..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="h-12 w-1/2 rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:border-white/10 dark:text-white"
                        />
                        {selectedProducts.length > 0 && (
                            <button
                                className="py-2 px-4 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none"
                                onClick={handleMultipleDelete}
                            >
                                Delete Selected
                            </button>
                        )}
                    </div>

                    {filteredData.length === 0 ? (
                        <p>No products found.</p>
                    ) : (
                        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" className="px-6 py-3">
                                        <input
                                            type="checkbox"
                                            onChange={(e) =>
                                                setSelectedProducts(
                                                    e.target.checked ? filteredData.map((product) => product.id) : []
                                                )
                                            }
                                            checked={selectedProducts.length === filteredData.length}
                                        />
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Product Name
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Features
                                    </th>
                                    <th scope="col" className="px-6 py-3">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.map((product) => (
                                    <tr
                                        key={product.id}
                                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                                    >
                                        <td className="px-6 py-4">
                                            <input
                                                type="checkbox"
                                                checked={selectedProducts.includes(product.id)}
                                                onChange={() => handleSelectProduct(product.id)}
                                            />
                                        </td>
                                        <td className="px-6 py-4">{product.product_name}</td>
                                        <td className="px-6 py-4">{product.features}</td>
                                        <td className="px-6 py-4">
                                            <button
                                                className="py-2 px-4 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none"
                                                onClick={() => handleEdit(product)}
                                            >
                                                Edit
                                            </button>
                                            <button
                                                className="py-2 px-4 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none ml-2"
                                                onClick={() => handleDelete(product.id)}
                                            >
                                                Delete
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </Card>

            <ToastContainer />
        </div>
    );
};

export default Products;
