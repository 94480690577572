import InputField from "components/fields/InputField";
import React, { useState } from "react";
import Footer from "components/footer/FooterAuthDefault";
import authImg from "assets/img/auth/auth.png";
import { Link, Routes, Route, Navigate } from "react-router-dom";
import routes from "routes.js";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import Dashboard from "layouts/admin/index";
import Defult from "views/admin/default/index";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { token } from 'service';


import 'react-toastify/dist/ReactToastify.css'; // Import CSS for styling

export default function SignIn(props) {
    

  const [token, setCount] = useState(0);

  const { label, id, extra, type, placeholder, variant, state, disabled } =
  props;

  const navigate = useNavigate();
  
  const handleNavigation = () => {
    // Navigate to a route using either path or object syntax
    navigate('/admin'); // Navigate by path
    // navigate({ pathname: '/new-route', state: { someData: 'value' } }); // Navigate with state

  };


  const showToast = (message) => {
    toast(message, {
      position: "top-right", // Adjust position as desired (top-left, bottom-center, etc.)
      autoClose: 5000, // Toast will close automatically after 5 seconds
    });
  };
  

  const handleClick = (e) => {
    showToast("" + e );
  };

  
 
    // Usernames
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
  
    
  
    // handaling the request.
    const handleLogin = async () => {



      try {
        const response = await fetch('https://api.strangeweb.info/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username, password }),
        });
  
        if (!response.ok) {
          throw new Error('Login failed');
        }
  
        const data = await response.json();

        if (data.error) {

          setError(data.error);
          console.log(data.error);
          handleClick(data.error)
       
        } else {  

          // Handle successful login (e.g., save token in localStorage)
          localStorage.setItem('token', data.token);
          localStorage.setItem('userId', data.user_id);
          setCount(data.token)

          // Redirect or perform any other actions after successful login
          handleNavigation()
        }
      } catch (error) {
        setError('Login failed. Please try again.');
        console.error('Error:', error);
      }

    };

  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
 
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
        Pacific Control 
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
         Admin login
        </p>
      
        <form
        onSubmit={(e) => {
          e.preventDefault();
          handleLogin();
        }}
      >
        <label>
        <label htmlFor={id} className={`text-sm text-navy-500 dark:text-white   ${ variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"}`}>
   {label}
  Username
 </label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${
              disabled === true
                ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
                : state === "error"
                ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
                : state === "success"
                ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
                : "border-gray-200 dark:!border-white/10 dark:text-white"
            }`}
          />
        </label>
        <br />
        <label>
        <label htmlFor={id} className={`text-sm text-navy-500 dark:text-white   ${ variant === "auth" ? "ml-1.5 font-medium" : "ml-3 font-bold"}`}>
   {label}
  Password
 </label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}

            className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${
              disabled === true
                ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
                : state === "error"
                ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
                : state === "success"
                ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
                : "border-gray-200 dark:!border-white/10 dark:text-white"
            }`}
          />
        </label>
        <br />
        <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-10" type="submit">Login</button>
      </form>


      <ToastContainer />
       
      </div>
    </div>
  );
}
