import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from "components/card";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Dashboard = () => {
  const [file, setFile] = useState(null);
  const [headingText, setHeadingText] = useState('');
  const [paragraph, setParagraph] = useState('');
  const [errors, setErrors] = useState([]);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [editId, setEditId] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedBanners, setSelectedBanners] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  const showToast = (message) => {
    toast(message, {
      position: "top-right",
      autoClose: 5000,
    });
  };

  const handleClick = (message) => {
    showToast(message);
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch('https://api.strangeweb.info/getmainbanners');
      if (!response.ok) throw new Error('API request failed!');
      const responseData = await response.json();
      setData(responseData);
      setFilteredData(responseData);
    } catch (error) {
      console.error('Error fetching data:', error);
      setErrors(['Failed to fetch data.']);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    setIsLoadingForm(true);

    const formData = new FormData();
    formData.append('upload_banner_image', file);
    formData.append('heading_text', headingText);
    formData.append('paragraph', paragraph);
    if (editId) formData.append('id', editId);

    try {
      await axios.post('https://api.strangeweb.info/mainbanner.php', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      handleClick(editId ? 'Banner Updated' : 'Banner Saved');
      setEditId(null);
      fetchData(); // Fetch data again after saving
    } catch (error) {
      handleClick('An error occurred while saving the banner.');
      console.error('Error:', error);
    } finally {
      setIsLoadingForm(false);
      setHeadingText('');
      setParagraph('');
      setFile(null);
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleEdit = (item) => {
    setHeadingText(item.heading_text);
    setParagraph(item.paragraph);
    setFile(null);
    setEditId(item.id);
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`https://api.strangeweb.info/delete_banner.php?id=${id}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.ok) {
        handleClick("Banner Removed");
        fetchData(); // Fetch data again after deletion
      } else {
        handleClick("Failed to remove banner.");
        console.error('Failed to delete banner:', response.statusText);
        setErrors(['Failed to delete banner.']);
      }
    } catch (error) {
      handleClick("Failed to remove banner.");
      console.error('Error deleting banner:', error);
      setErrors(['Failed to delete banner.']);
    }
  };

  const handleBulkDelete = async () => {
    if (selectedBanners.length === 0) {
      handleClick("No banners selected.");
      return;
    }

    try {
      // Loop through selected banners and delete each one
      for (const id of selectedBanners) {
        await handleDelete(id); // Call handleDelete for each selected banner
      }
      setSelectedBanners([]); // Clear selected banners after successful deletion
    } catch (error) {
      handleClick("Failed to remove selected banners.");
      console.error('Error deleting banners:', error);
      setErrors(['Failed to delete selected banners.']);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    const filtered = data.filter(item => item.heading_text.toLowerCase().includes(e.target.value.toLowerCase()));
    setFilteredData(filtered);
    setCurrentPage(1); // Reset to first page on search
  };

  const handleSelectBanner = (id) => {
    setSelectedBanners(prevSelected =>
      prevSelected.includes(id) ? prevSelected.filter(bannerId => bannerId !== id) : [...prevSelected, id]
    );
  };

  const handleToggleAll = () => {
    if (selectedBanners.length === filteredData.length) {
      setSelectedBanners([]); // Deselect all
    } else {
      setSelectedBanners(filteredData.map(item => item.id)); // Select all
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(filteredData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setFilteredData(items);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="mt-5">
        <Card extra={"w-full h-full sm:overflow-auto p-6"}>
          <header className="relative flex items-center justify-between pt-4">
            <div className="text-xl font-bold text-navy-700 dark:text-white">
              Main Banner Images
            </div>
          </header>

          <div className="mt-4 flex items-center justify-between">
            <input
              type="text"
              placeholder="Search by heading"
              value={searchQuery}
              onChange={handleSearch}
              className="w-full p-2 border rounded"
            />
            {selectedBanners.length > 0 && (
              <button
                onClick={handleBulkDelete}
                className="bg-red-700 hover:bg-red-800 text-white font-bold py-2 px-4 rounded ml-4"
              >
                Delete 
              </button>
            )}
          </div>

          <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="banners">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="relative overflow-x-auto"
                  >
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            <input
                              type="checkbox"
                              onChange={handleToggleAll}
                              checked={selectedBanners.length === filteredData.length}
                            />
                          </th>
                          <th scope="col" className="px-6 py-3">Heading</th>
                          <th scope="col" className="px-6 py-3">Paragraph</th>
                          <th scope="col" className="px-6 py-3">Actions</th>
                        </tr>
                      </thead>

                      <tbody>
                        {isLoading ? (
                          <tr>
                            <td colSpan="5" className="text-center py-4">Loading...</td>
                          </tr>
                        ) : currentItems.length > 0 ? (
                          currentItems.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                              {(provided) => (
                                <tr
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                                >
                                  <td className="px-6 py-4">
                                    <input
                                      type="checkbox"
                                      onChange={() => handleSelectBanner(item.id)}
                                      checked={selectedBanners.includes(item.id)}
                                    />
                                  </td>
                                  <th hidden scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {item.banner_image ? (
                                      <img
                                        src={`https://api.strangeweb.info/uploads/${item.banner_image}`}
                                        alt={item.heading_text}
                                        className="banner_image shadow-lg"
                                      />
                                    ) : (
                                      <span className="text-red-500">Image Not Found</span>
                                    )}
                                  </th>
                                  <td className="px-6 py-4">
                                    <h2 className="text-lg font-bold">{item.heading_text}</h2>
                                  </td>
                                  <td className="px-6 py-4">
                                    <p className="text-gray-700">{item.paragraph}</p>
                                  </td>
                                  <td className="px-6 py-4">
                                    <button
                                      onClick={() => handleEdit(item)}
                                      type="button"
                                      className="focus:outline-none text-white bg-blue-500 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    >
                                      Edit
                                    </button>
                                    <button
                                      onClick={() => handleDelete(item.id)}
                                      type="button"
                                      className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                                    >
                                      Remove
                                    </button>
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center py-4">No data found.</td>
                          </tr>
                        )}
                        {provided.placeholder}
                      </tbody>
                    </table>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>

          {/* Pagination Controls */}
          {totalPages > 1 && (
            <div className="mt-4 flex justify-between">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              >
                Previous
              </button>
              <span className="self-center">Page {currentPage} of {totalPages}</span>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              >
                Next
              </button>
            </div>
          )}
        </Card>
      </div>

      <br />

      <Card extra={"w-full h-full sm:overflow-auto p-5"}>
        <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            {editId ? 'Edit Banner ' : 'Add Banner '}
          </div>
        </header>

        <form onSubmit={handleSubmit}>
          <div className="mt-5">
            <input
              type="file"
              id="upload_banner_image"
              onChange={handleFileChange}
              disabled={isLoadingForm}
              className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 inputdnone text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
              hidden
            />
          </div>
          <div className="mt-5">
            <label htmlFor="heading_text">Heading:</label>
            <input
              type="text"
              placeholder="Add heading"
              id="heading_text"
              value={headingText}
              onChange={(e) => setHeadingText(e.target.value)}
              disabled={isLoadingForm}
              className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
            />
          </div>
          <div className="mt-5">
            <label htmlFor="paragraph">Paragraph:</label>
            <textarea
              id="paragraph"
              placeholder="Add paragraph"
              value={paragraph}
              onChange={(e) => setParagraph(e.target.value)}
              disabled={isLoadingForm}
              className="mt-2 flex h-32 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
            />
          </div>
          <div className="mt-10 flex space-x-4">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              type="submit"
              disabled={isLoadingForm}
            >
              {isLoadingForm ? 'Uploading...' : 'Upload & Save'}
            </button>
            {editId && (
              <button
                type="button"
                onClick={() => {
                  setEditId(null);
                  setHeadingText('');
                  setParagraph('');
                  setFile(null);
                }}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                disabled={isLoadingForm}
              >
                Cancel
              </button>
            )}
          </div>
        </form>
        {errors.length > 0 && (
          <div className="mt-5">
            <h3 className="font-bold">Errors:</h3>
            <ul className="list-disc pl-5">
              {errors.map((error, index) => (
                <li key={index} className="text-red-500">{error}</li>
              ))}
            </ul>
          </div>
        )}
      </Card>
      <ToastContainer />
    </div>
  );
};

export default Dashboard;
