import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import Helmet from 'react-helmet';
import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
  
const App = ( ) => {

  useEffect(() => {
    document.title = "Pacific Control India Private Limited";
  }, []); // [] makes sure this effect runs only once, like componentDidMount

  return (
   
    <Routes>
      <Route path="dashboard/*" element={<AuthLayout />} />
      <Route path="admin/*" element={<AdminLayout />} />
      <Route path="rtl/*" element={<RtlLayout />} />
      <Route path="/" element={<Navigate to="/dashboard" replace />} />
    </Routes>
  );
  
};

export default App; 
