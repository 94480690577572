
import React, { useState, useEffect } from 'react';
import Card from 'components/card';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Marketplace = () => {
  const [loading, setLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [images, setImages] = useState([]);
  const [uploadStatus, setUploadStatus] = useState('');
  const [error, setError] = useState(null);
  const [selectedImageIds, setSelectedImageIds] = useState(new Set());
  const [selectedImage, setSelectedImage] = useState(null);

  const showToast = (message) => {
    toast(message, {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: 'colored',
    });
  };

  const handleClick = (message) => {
    showToast(message);
  };

  const fetchImages = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://api.strangeweb.info/getgaleryimages');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setImages(data);
    } catch (error) {
      setError(error.message);
      showToast(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  const handleUpload = async () => {
    if (selectedFiles) {
      setLoading(true);
      const formData = new FormData();
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append('images[]', selectedFiles[i]);
      }

      try {
        const response = await fetch('https://api.strangeweb.info/galleryimageupload.php', {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          const data = await response.json();
          setUploadStatus(data);
          handleClick('All Images Uploaded');
          fetchImages();
        } else {
          const errorText = await response.text();
          throw new Error(`Failed to upload images: ${errorText}`);
        }
      } catch (error) {
        console.error('Error uploading images:', error);
        setUploadStatus({ status: 'error', message: 'Failed to upload images' });
        showToast(`Error: ${error.message}`);
      } finally {
        setLoading(false);
      }
    } else {
      setUploadStatus({ status: 'error', message: 'No images selected' });
      showToast('No images selected');
    }
  };

  const handleDeleteImage = async (imageIds) => {
    setLoading(true);
    console.log('Attempting to delete images with IDs:', imageIds);
    try {
      const formData = new FormData();
      imageIds.forEach(id => formData.append('image_ids[]', id));

      const response = await fetch('https://api.strangeweb.info/deletegaleryimages', {
        method: 'POST',
        body: formData,
      });

      console.log('Response status:', response.status);
      console.log('Response headers:', response.headers);

      if (response.ok) {
        const data = await response.json();
        console.log('Response data:', data);
        setImages(images.filter(image => !imageIds.includes(image.id)));
        showToast(data.message);
        setSelectedImageIds(new Set()); // Clear selected images after deletion
      } else {
        const errorText = await response.text();
        throw new Error(`Failed to delete images: ${errorText}`);
      }
    } catch (error) {
      console.error('Error deleting images:', error);
      showToast(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (imageId) => {
    setSelectedImageIds(prevSelectedIds => {
      const newSelectedIds = new Set(prevSelectedIds);
      if (newSelectedIds.has(imageId)) {
        newSelectedIds.delete(imageId);
      } else {
        newSelectedIds.add(imageId);
      }
      return newSelectedIds;
    });
  };

  const handleDeleteSelected = () => {
    if (selectedImageIds.size > 0) {
      handleDeleteImage(Array.from(selectedImageIds));
    } else {
      showToast('No images selected for deletion');
    }
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="container mx-auto p-4">
      <Card extra="w-full p-6 bg-white shadow-md rounded-lg mb-5">
        <header className="flex items-center justify-between mb-4">
          <h2 className="text-2xl font-semibold text-gray-800">Add Gallery Images</h2>
        </header>
        <div className='space-y-4'>
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
          />
          <button
            className='w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg py-2.5'
            onClick={handleUpload}
            disabled={loading}
          >
            {loading ? 'Uploading...' : 'Upload'}
          </button>
        </div>
      </Card>

      <Card extra="w-full p-6 bg-white shadow-md rounded-lg mt-5">
        <header className="flex items-center justify-between mb-4">
          <h2 className="text-2xl font-semibold text-gray-800">All Gallery Images</h2>
        </header>
        <div>
          {loading ? (
            <div className="text-center">Loading...</div>
          ) : images.length === 0 ? (
            <p className="text-center text-gray-600">No images found.</p>
          ) : (
            <>
              {selectedImageIds.size > 0 && (
                <button
                  className='mb-4 p-5 text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:ring-red-300 font-medium rounded-lg py-2.5'
                  onClick={handleDeleteSelected}
                  disabled={loading}
                >
                  {loading ? 'Deleting...' : 'Delete Selected'}
                </button>
              )}
              <ul className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
                {images.map((image) => (
                  <li key={image.id} className="relative group">
                    <img
                      src={`https://api.strangeweb.info/${image.image_path}`}
                      alt={`Gallery Image ${image.id}`}
                      className="w-full h-40 object-cover rounded-lg shadow-lg cursor-pointer"
                      onClick={() => handleImageClick(image)}
                    />
                    <div
                      className='absolute top-2 right-2 bg-gray-800 text-white p-1 rounded-full cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity'
                      onClick={() => handleDeleteImage([image.id])}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </div>
                    <input
                      type="checkbox"
                      checked={selectedImageIds.has(image.id)}
                      onChange={() => handleCheckboxChange(image.id)}
                      className="absolute bottom-2 left-2 bg-white border border-gray-300 rounded-lg"
                    />
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </Card>

      {selectedImage && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative">
            <button
              className="absolute top-2 right-2 text-white text-2xl"
              onClick={closeModal}
            >
              &times;
            </button>
            <img
              src={`https://api.strangeweb.info/${selectedImage.image_path}`}
              alt={`Gallery Image ${selectedImage.id}`}
              className="max-w-full max-h-screen"
            />
          </div>
        </div>
      )}

      <ToastContainer />
      </div>
  );
};

export default Marketplace;
