import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Card from 'components/card'; // Ensure this path is correct

const Services = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('Default description');
  const [price, setPrice] = useState('0');
  const [editingServiceId, setEditingServiceId] = useState(null);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedIds, setSelectedIds] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingForm(true);

    const serviceData = { name, description, price };

    try {
      const url = editingServiceId
        ? `https://api.strangeweb.info/addservices`
        : `https://api.strangeweb.info/addservices`;

      const response = editingServiceId
        ? await axios.put(url, { id: editingServiceId, ...serviceData })
        : await axios.post(url, serviceData);

      if (response.data.success) {
        toast.success(editingServiceId ? 'Service updated' : 'Service added');
        setName('');
        setEditingServiceId(null);
        fetchData();
        window.scrollTo(0, 0); // Redirect to the top of the page
      } else {
        toast.error(editingServiceId ? 'Failed to update service' : 'Failed to add service');
      }
    } catch (error) {
      console.error('Error saving service:', error);
      toast.error('An error occurred');
    } finally {
      setIsLoadingForm(false);
    }
  };

  const handleDelete = async (ids) => {
    try {
      const response = await axios.post('https://api.strangeweb.info/delservices', { ids });
      if (response.data.success) {
        toast.success('Services deleted');
        setSelectedIds([]); // Clear selected IDs
        fetchData();
      } else {
        toast.error('Failed to delete services');
      }
    } catch (error) {
      console.error('Error deleting services:', error);
      toast.error('An error occurred');
    }
  };

  const handleEdit = (service) => {
    setName(service.name);
    setDescription(service.description);
    setPrice(service.price);
    setEditingServiceId(service.id);
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get('https://api.strangeweb.info/viewservices');
      if (response.data && response.data.services) {
        setData(response.data.services);
        setFilteredData(response.data.services); // Initialize filtered data
      } else {
        setData([]);
        setFilteredData([]);
      }
    } catch (error) {
      console.error('Error fetching services:', error);
      setData([]);
      setFilteredData([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      setFilteredData(data.filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase())));
    } else {
      setFilteredData(data);
    }
  }, [searchTerm, data]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCheckboxChange = (id) => {
    setSelectedIds(prevSelectedIds =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter(selectedId => selectedId !== id)
        : [...prevSelectedIds, id]
    );
  };

  return (
    <div>
      <Card extra="w-full h-full sm:overflow-auto p-5">
        <header className="relative flex items-center justify-between pt-4">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            {editingServiceId ? 'Edit Service' : 'Add Services'}
          </div>
        </header>

        <form onSubmit={handleSubmit}>
          <div className="mt-5">
            <label htmlFor="heading_text">Name:</label>
            <input
              type="text"
              placeholder="Add heading"
              id="heading_text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled={isLoadingForm}
              className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
              required
            />
          </div>

          {/* Hidden fields */}
          <input 
            type="hidden" 
            value={description} 
            onChange={(e) => setDescription(e.target.value)}
          />
          <input 
            type="hidden" 
            value={price} 
            onChange={(e) => setPrice(e.target.value)}
          />

          <button 
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-10"
            type="submit"
            disabled={isLoadingForm}
          >
            {isLoadingForm ? 'Loading...' : editingServiceId ? 'Update' : 'Submit'}
          </button>
        </form> 
      </Card>

      <div className="mt-5">
        <Card extra="w-full h-full sm:overflow-auto p-6">
          <header className="relative flex items-center justify-between pt-4">
            <div className="text-xl font-bold text-navy-700 dark:text-white">
              List of all services
            </div>
          </header>

          <div className="mt-8 flex items-center space-x-4">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearch}
              className="flex-grow h-12 rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:border-white/10 dark:text-white"
            />
            {selectedIds.length > 0 && (
              <button
                onClick={() => handleDelete(selectedIds)}
                type="button"
                className="bg-red-700 hover:bg-red-800 text-white font-bold py-2 px-4 rounded"
              >
                Delete Selected
              </button>
            )}
          </div>

          <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
            <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">Select</th>
                    <th scope="col" className="px-6 py-3">Name</th>
                    <th scope="col" className="px-6 py-3 text-end pr-20">Actions</th>
                  </tr>
                </thead>

                {isLoading ? (
                  <tbody>
                    <tr>
                      <td colSpan="3" className="text-center py-4">Loading...</td>
                    </tr>
                  </tbody>
                ) : filteredData.length > 0 ? (
                  <tbody>
                    {filteredData.map((item) => (
                      <tr key={item.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        <td className="px-6 py-4">
                          <input
                            type="checkbox"
                            checked={selectedIds.includes(item.id)}
                            onChange={() => handleCheckboxChange(item.id)}
                          />
                        </td>
                        <td className="px-6 py-4">
                          <h2 className="text-lg font-bold">{item.name}</h2>
                        </td>
                        <td className="px-6 py-4 text-end">
                          <button
                            onClick={() => handleEdit(item)}
                            type="button"
                            className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-900"
                          >
                            Edit
                          </button>
                          <button
                            onClick={() => handleDelete([item.id])}
                            type="button"
                            className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                          >
                            Remove
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="3" className="text-center py-4">No data found.</td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </Card>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Services;
