import React, { useState, useEffect, useRef } from 'react';
import Card from 'components/card';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

const Tables = () => {
    const [heading, setHeading] = useState('');
    const [points, setPoints] = useState(['']);
    const [data, setData] = useState([]);
    const [editId, setEditId] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    const [selectedItems, setSelectedItems] = useState([]);
    const formRef = useRef(null);

    const showToast = (message) => {
        toast(message, {
            position: "top-right",
            autoClose: 5000,
        });
    };

    const fetchData = async () => {
        try {
            const response = await fetch('https://api.strangeweb.info/getallprotfolios.php');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            setData(jsonData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const point1 = points.join(', ');
        const action = editId ? 'update' : 'insert';
        const body = { heading, point1, action };
        if (editId) {
            body.id = editId;
        }

        try {
            const response = await fetch('https://api.strangeweb.info/addprotfolios.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });

            const result = await response.json();

            if (result.success) {
                showToast(editId ? 'Portfolio Updated' : 'Portfolio Added');
                setHeading('');
                setPoints(['']);
                setEditId(null);
                fetchData();
            } else {
                showToast('Error: ' + result.message);
            }
        } catch (error) {
            showToast('Error submitting form: ' + error.message);
        }
    };

    const handlePointChange = (index, value) => {
        const updatedPoints = [...points];
        updatedPoints[index] = value;
        setPoints(updatedPoints);
    };

    const addPointField = () => {
        setPoints([...points, '']);
        showToast('Point Field Added');
    };

    const removePointField = (index) => {
        const updatedPoints = points.filter((_, i) => i !== index);
        setPoints(updatedPoints);
        showToast('Point Field Removed');
    };

    const handleDelete = async (id) => {
        try {
            const response = await fetch('https://api.strangeweb.info/delete_protfplio.php', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id }),
            });

            const result = await response.json();

            if (response.ok) {
                fetchData();
                showToast('Portfolio Deleted');
            } else {
                showToast(result.error || 'Error deleting record');
            }
        } catch (error) {
            console.error('Error:', error);
            showToast('Network error or server not reachable');
        }
    };

    const handleBulkDelete = async () => {
        try {
            await Promise.all(selectedItems.map(id =>
                fetch('https://api.strangeweb.info/delete_protfplio.php', {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ id }),
                })
            ));
            fetchData();
            setSelectedItems([]);
            showToast('Selected portfolios deleted');
        } catch (error) {
            console.error('Error:', error);
            showToast('Network error or server not reachable');
        }
    };

    const handleEdit = (item) => {
        setHeading(item.heading);
        setPoints(item.point1.split(', '));
        setEditId(item.id);
        if (formRef.current) {
            formRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleSelect = (id) => {
        setSelectedItems(prevSelected => 
            prevSelected.includes(id) 
                ? prevSelected.filter(item => item !== id) 
                : [...prevSelected, id]
        );
    };

    const filteredData = data.filter(item => 
        item.heading.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const displayedData = filteredData.slice(
        (currentPage - 1) * itemsPerPage, 
        currentPage * itemsPerPage
    );

    return (
        <div>
            <Card extra="w-full h-full sm:overflow-auto p-6 mt-5">
                <header className="relative flex items-center justify-between pt-4">
                    <div className="text-xl font-bold text-navy-700 dark:text-white">
                        {editId ? 'Edit Portfolio' : 'Add Portfolio'}
                    </div>
                </header>
                <div ref={formRef}>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label>Heading:</label>
                            <input
                                type="text"
                                value={heading}
                                onChange={(e) => setHeading(e.target.value)}
                                className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                            />
                        </div>
                        <div>
                            {points.map((point, index) => (
                                <div key={index} className="flex items-center mt-2">
                                    <div className="flex-grow">
                                        <label>{`Point ${index + 1}:`}</label>
                                        <input
                                            type="text"
                                            value={point}
                                            onChange={(e) => handlePointChange(index, e.target.value)}
                                            className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none border-gray-200 dark:!border-white/10 dark:text-white"
                                        />
                                    </div>
                                    <button
                                        type="button"
                                        onClick={() => removePointField(index)}
                                        className="ml-2 mt-5 py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                    >
                                        Remove
                                    </button>
                                </div>
                            ))}
                        </div>
                        <button
                            type="button"
                            onClick={addPointField}
                            className="mt-5 py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        >
                            Add Point
                        </button>
                        <button
                            type="submit"
                            className="mt-5 py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                        >
                            {editId ? 'Update' : 'Submit'}
                        </button>
                    </form>
                </div>
            </Card>

            <Card extra="w-full h-full sm:overflow-auto p-6 mt-5">
                <header className="relative flex items-center justify-between pt-4">
                    <div className="text-xl font-bold text-navy-700 dark:text-white">
                        All Portfolios
                    </div>
                </header>
                <div className="flex justify-between items-center mb-4">
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="p-2 border border-gray-300 rounded"
                    />
                    {selectedItems.length > 0 && (
                        <button
                            onClick={handleBulkDelete}
                            className="py-2 px-4 text-white bg-red-500 rounded-lg hover:bg-red-600 focus:outline-none"
                        >
                            Delete Selected
                        </button>
                    )}
                </div>

                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                <input
                                    type="checkbox"
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelectedItems(displayedData.map((item) => item.id));
                                        } else {
                                            setSelectedItems([]);
                                        }
                                    }}
                                    checked={selectedItems.length === displayedData.length}
                                />
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Heading
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Points
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayedData.map((item) => (
                            <tr
                                key={item.id}
                                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                            >
                                <td className="px-6 py-4">
                                    <input
                                        type="checkbox"
                                        checked={selectedItems.includes(item.id)}
                                        onChange={() => handleSelect(item.id)}
                                    />
                                </td>
                                <td className="px-6 py-4">{item.heading}</td>
                                <td className="px-6 py-4">{item.point1}</td>
                                <td className="px-6 py-4">
                                    <button
                                        onClick={() => handleEdit(item)}
                                        className="py-2 px-4 text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none me-2"
                                    >
                                        Edit
                                    </button>
                                    <button
                                        onClick={() => handleDelete(item.id)}
                                        className="py-2 px-4 text-white bg-red-500 rounded-lg hover:bg-red-600 focus:outline-none"
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="flex justify-center mt-4">
                    <button
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="py-2 px-4 bg-gray-300 rounded-lg me-2 disabled:opacity-50"
                    >
                        Previous
                    </button>
                    <button
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="py-2 px-4 bg-gray-300 rounded-lg disabled:opacity-50"
                    >
                        Next
                    </button>
                </div>
            </Card>

            <ToastContainer />
        </div>
    );
};

export default Tables;
